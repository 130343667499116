<template>
	<div class="blog">
		<ArticleNavigation :blogInfo="blogInfo"/>

		<v-container>
			<ArticlesTable v-if='blogInfo.id && userInfo.id' :blogInfo="blogInfo" :userInfo="userInfo" />
		</v-container>
	</div>
</template>

<script>
	import ArticleNavigation from '@/components/modules/@blog/Navigation';
	import ArticlesTable from '@/components/modules/@blog/ArticlesTable';

	export default {
		head: {
			title: {
				inner: "Articles - Blog"
			}
		},
		name: 'Articles',
		props: {
			'id': {
				type: String
			}
		},
		components: {
			ArticleNavigation,
			ArticlesTable
		},
		data() {
			return {
				userInfo: this.$models.user,

				blogInfo: {},
				articles: [],
			};
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = {
					...doc.data(),
					id: doc.id
				};

				this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.id).onSnapshot(doc => {
					this.blogInfo = {
						...doc.data(),
						id: doc.id
					}
				});
			});
		}
	}
</script>
