<template>
	<div class="articleNav">
		<v-app-bar color="primary" dark>
			<v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" absolute temporary>
			<v-list nav dense>
				<v-list-item router :to="`/blog/${this.blogInfo.id}`">
					<v-list-item-icon>
						<v-icon>mdi-view-dashboard</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Tableau de bord</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item router :to="`/blog/${this.blogInfo.id}/add`">
					<v-list-item-icon>
						<v-icon>mdi-newspaper-plus</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Ajouter un article</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item router :to="`/blog/${this.blogInfo.id}/articles`">
					<v-list-item-icon>
						<v-icon>mdi-newspaper-variant-multiple-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Tous les articles</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>


<script>
	export default {
		props: {
			blogInfo: {
				type: Object
			}
		},
		data() {
			return {
				drawer: false,
				group: null,

				links: []
			}
		},
		mounted() {
			this.links.push(
				{
					icon: 'mdi-view-dashboard',
					text: 'Tableau de bord',
					route: `/record/blog/${this.blogInfo.id}`
				},
				{
					icon: 'mdi-newspaper-plus',
					text: 'Ajouter un article',
					route: `/record/blog/${this.blogInfo.id}/add`
				}
			);
		}
	}
</script>

